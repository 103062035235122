import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { EmptyPageComponent } from './pages/empty-page/empty-page.component';
import { LoginOtpComponent } from './pages/login-otp/login-otp.component';
import { PagesLayoutComponent } from './layout/pages-layout/pages-layout.component';
import { PaymentOptionsComponent } from './pages/payment-options/payment-options.component';
import { PaymentMethodsComponent } from './pages/payment-methods/payment-methods.component';
import { SuccessPaymentComponent } from './pages/success-payment/success-payment.component';
import { ConfirmPaymentUsingBirthDateComponent } from './pages/confirm-payment-using-birth-date/confirm-payment-using-birth-date.component';
import { OtpTransactionConfirmationComponent } from './pages/otp-transaction-confirmation/otp-transaction-confirmation.component';
import { ThreeDsPaymentComponent } from './pages/three-ds-payment/three-ds-payment.component';
import { ApplePayComponent } from './pages/apple-pay/apple-pay.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'Payment',
        component: LoginComponent,
      },
      {
        path: 'login-otp-verification',
        component: LoginOtpComponent,
      },
      {
        path: 'paymentResult',
        component: EmptyPageComponent,
      },
      {
        path: '',
        component: PagesLayoutComponent,
        children: [
          {
            path: 'payment-options',
            component: PaymentOptionsComponent,
          },
          {
            path: 'payment-methods',
            component: PaymentMethodsComponent,
          },
          {
            path: 'payment-success',
            component: SuccessPaymentComponent,
          },
          {
            path: 'birth-date-verification',
            component: ConfirmPaymentUsingBirthDateComponent,
          },
          {
            path: 'otp-verification',
            component: OtpTransactionConfirmationComponent,
          },
          {
            path: 'card-payment',
            component: ThreeDsPaymentComponent,
          },
          {
            path: 'apply-pay-payment',
            component: ApplePayComponent,
          },
        ],
      },
      { path: '**', component: EmptyPageComponent },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
