import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { LoaderService } from './services/Loader.Service';
import { ActivatedRoute, Router } from '@angular/router';
import { CheckoutService } from './services/checkout.service';
import { GetOrderDetailsReq, OrderDetailsRes } from './models/getOrderDetails';
import { ChangeLanguageService } from './services/helper/change-language.service';
import { ApplicationLanguages } from 'src/app/enums/lang';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ErrorPopupService } from './services/error-popup.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  orderDetailsRes: OrderDetailsRes | undefined;
  applicationLang = ApplicationLanguages;
  title = 'Checkout';
  isLoading: boolean = false;

  destroyRef = inject(DestroyRef);

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService,
    private checkoutService: CheckoutService,
    private changeLanguageService: ChangeLanguageService,
    private errorPopupService: ErrorPopupService,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.loaderService.isLoading.subscribe((e) => {
      this.isLoading = e.valueOf();

      if (
        this.orderDetailsRes &&
        !this.orderDetailsRes?.success &&
        !this.isLoading
      ) {
        this.openErrorBox(
          this.orderDetailsRes?.title,
          this.orderDetailsRes?.message
        );
      }
    });

    this.route.queryParamMap.subscribe(() => {
      const token = this.route.snapshot.queryParamMap.get('token');

      if (token) this.getOrderDetailsByToken(token ?? '');
      this.subscribeOrderDetails();
    });
  }

  get savedLanguage() {
    return localStorage.getItem('lang');
  }

  getOrderDetailsByToken(token: string) {
    if (this.isLoginPage() || !localStorage.getItem('orderDetailsRes')) {
      localStorage.clear();
      const getOrderDetailsReq: GetOrderDetailsReq = { token: token };

      this.checkoutService.GetOrderDetails(getOrderDetailsReq);
    } else {
      const savedOrderDetails = localStorage.getItem('orderDetailsRes');
      if (savedOrderDetails) {
        const orderDetailsRes = JSON.parse(savedOrderDetails);
        this.checkoutService.orderDetails.next(orderDetailsRes);
      }
    }
  }

  isLoginPage() {
    return (
      this.router.url.toLowerCase().includes('/payment?') ||
      this.router.url.toLowerCase() === '/payment'
    );
  }

  subscribeOrderDetails() {
    this.checkoutService.orderDetails
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((res: OrderDetailsRes | undefined) => {
        this.orderDetailsRes = res;
        if (res) {
          this.setAppLanguage(res?.data?.guestOrderData?.lang ?? 'en');

          if (!res?.success && !this.isLoading) {
            this.openErrorBox(res?.title, res?.message);
          }
        }
      });
  }

  setAppLanguage(orderLang: string) {
    const currentAppLang = localStorage.getItem('lang');

    const lang =
      currentAppLang ??
      (orderLang == this.applicationLang.Ar
        ? this.applicationLang.Ar
        : this.applicationLang.En);

    this.changeLanguageService.changeLanguage(lang);
  }

  openErrorBox(
    title: string = this.translate.instant('paying-problem-title'),
    message: string = this.translate.instant('paying-problem-subtitle')
  ) {
    this.errorPopupService.openModal(title, message);
  }
}
